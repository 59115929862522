import { WEB_ANALYTICS_PPVF_CONTROL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain Control", "has-active": false, "has-filter-inactive": false } }, [_c("text-field", { key: "CountryID", attrs: { "data-index": "countryID", "title": "Country ID" } }), _c("text-field", { key: "CurrentYearCostSet", attrs: { "data-index": "currentYearCostSet", "title": "Current Year Cost Set" } }), _c("text-field", { key: "PrevYearCostSet", attrs: { "data-index": "prevYearCostSet", "title": "Prev Year Cost Set" } }), _c("text-field", { key: "NextYearCostSet", attrs: { "data-index": "nextYearCostSet", "title": "Next Year Cost Set" } }), _c("text-field", { key: "WattiesShippingDays", attrs: { "data-index": "wattiesShippingDays", "title": "Watties Shipping Days" } }), _c("text-field", { key: "HJHeinz Cust No", attrs: { "data-index": "hjHeinzCustNo", "title": "HJHeinzCustNo" } }), _c("text-field", { key: "LagLocal", attrs: { "data-index": "lagLocal", "title": "Lag Local" } }), _c("text-field", { key: "lagImport", attrs: { "data-index": "lagImport", "title": "Lag Import" } }), _c("text-field", { key: "BaseCurrency", attrs: { "data-index": "baseCurrency", "title": "Base Currency" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "Control"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Control = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.input-parameters", "resource-id-name": "countryID", "api-url": _vm.apiUrl, "edit-route": "/web-analytics/control/:id", "page": _vm.page } }, [_c("control")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Control
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_CONTROL,
      apiUrl,
      Control,
      itemsMenu: [
        {
          key: "profitability",
          title: "PPV-Forecast",
          path: ""
        },
        {
          key: "control",
          title: "Control",
          path: "/web-analytics/control"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
